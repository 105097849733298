.index-signal-table {
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #333;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
