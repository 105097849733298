/* Navbar Styles */
.navbar {
    background-color: #56686e;
    padding: 10px 0;
}

.container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 8px;
}

.logo {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
}

.logo:hover {
    text-decoration: underline;
}

.nav-links {
    list-style: none;
    display: flex;
}

.nav-links li {
    margin-right: 20px;
}

.nav-links li a {
    color: #fff;
    text-decoration: none;
}

.nav-links li a:hover {
    text-decoration: underline;
}

/* .search-bar {
    display: flex;
    width: 50%;
}

.search-bar input[type="text"],
.search-bar button {
    padding: 8px;
    border: none;
    border-radius: 4px;
}

.search-bar input[type="text"] {
    margin-right: 8px;
    width: 50%;
}

.search-bar button {
    background-color: #0077cc;
    color: #fff;
    cursor: pointer;
} */
